import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import Layout from './Layout';
import { ARCHIVED_PAGE_BASE_URL } from './Layout';

export const heapEnvironmentId = '175236400'; // heap production environment

export default function Defaults({ children }: { children: React.ReactNode }) {
  const TechLunchRoute = `/${ARCHIVED_PAGE_BASE_URL}/techlunch`;
  const isTechLunchPage = useRouter().route === TechLunchRoute;

  const isArchivedPage = useRouter().route.includes(`/${ARCHIVED_PAGE_BASE_URL}`);

  const heapScript = (
    <Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
          heap.load(${heapEnvironmentId});`
        }}
      />
    </Fragment>
  );

  return (
    <>
      {isArchivedPage ? (
        <>
          <Head>
            <title>IncepTeam.com</title>
            {isTechLunchPage ? (
              <link rel="icon" href="/techlunch-favicon.ico" />
            ) : (
              <link rel="icon" href="/favicon.ico" />
            )}
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;900&display=swap"
              rel="stylesheet"
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            {heapScript}
          </Head>

          <Layout>{children}</Layout>
        </>
      ) : (
        <>
          <Head>
            <title>IncepTeam.com</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            {heapScript}
          </Head>
          {children}
        </>
      )}
    </>
  );
}
