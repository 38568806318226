/* eslint-disable @typescript-eslint/no-empty-function */
import '../styles/globals.scss';
import { AppProps } from 'next/app';
import Defaults from '../lib/components/Defaults';
import React, { createContext, useRef, useState } from 'react';

export const SCROLLING_UP = 1;
export const SCROLLING_DOWN = -1;

export const PageContext = createContext({
  currentHash: 'home',
  setCurrentHash: (_page: string) => {},
  scrollDirection: SCROLLING_DOWN,
  setScrollDirection: (_direction: number) => {},
  setFullScreenAPI: (fullScreenAPI: any) => {},
  scrollTo: (hash: string) => {}
});

export const CurrentPageContextProvider = (props: { children: React.ReactNode | React.ReactNode[] }) => {
  const [currentHash, setCurrentHash] = useState<string>('home');
  const [scrollDirection, setScrollDirection] = useState<number>(SCROLLING_DOWN);
  const fullScreenAPI = useRef(undefined);

  const setFullScreenAPI = (api: any) => {
    fullScreenAPI.current = api;
  };

  const scrollTo = (hash: string) => {
    fullScreenAPI.current?.moveTo(hash, 0);
  };

  return (
    <PageContext.Provider
      value={{ currentHash, setCurrentHash, scrollDirection, setScrollDirection, setFullScreenAPI, scrollTo }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <CurrentPageContextProvider>
      <Defaults>
        <Component {...pageProps} />
      </Defaults>
    </CurrentPageContextProvider>
  );
}
