import { LayoutGroup } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../pages/_app';
import styles from '../../styles/Layout.module.scss';

export const ARCHIVED_PAGE_BASE_URL = 'archived';

const routesToTitles = {
  home: 'Home',
  work: 'Work',
  careers: 'Careers',
  company: 'Company',
  contact: 'Contact',
  team: 'Team',
  projects: 'Projects',
  techlunch: 'TechLunch'
};

export default function Layout({ children }: { children: React.ReactNode }) {
  const [isMobileNavigationOpen, setMobileNavigationState] = React.useState(false);
  const [title, setTitle] = useState<string>('');
  const [sectionHash, setSectionHash] = useState('');
  const toggle = (state: boolean) => () => setMobileNavigationState(state);
  const router = useRouter();
  const { currentHash } = useContext(PageContext);
  const [isNavigationShown, setNavigationVisible] = useState(true);

  useEffect(() => {
    onRouteChange(router.route);

    router.events.on('routeChangeComplete', onRouteChange);
    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, []);

  useEffect(() => {
    const hash = getCurrentHashMainPart();
    if (hash && router.pathname === `/${ARCHIVED_PAGE_BASE_URL}`) {
      setTitle(routesToTitles[hash] || 'Home');
      setSectionHash(hash);
    }
  }, [currentHash, router.pathname]);

  const getCurrentHashMainPart = () => {
    return currentHash?.split('-')?.[0];
  };

  const getMainPathPart = (url: string) => {
    const pathParts = url?.split('/');
    const path = pathParts.length > 1 ? pathParts[1] : '';
    return path;
  };

  const onRouteChange = (url: string) => {
    const path = getMainPathPart(url);
    setTitle(routesToTitles[path] || 'Home');
    if (routesToTitles[path] === routesToTitles.techlunch) {
      setNavigationVisible(false);
    }
  };

  const mobileNavigationContent = (
    <MobileNavigation onClose={toggle(false)} currentHashMainPart={getCurrentHashMainPart()} />
  );

  return (
    <>
      {isNavigationShown && <DesktopNavigation activeRoute={sectionHash} />}
      {isNavigationShown && { mobileNavigationContent } && (
        <>
          <div className={styles.mobileNavigation}>
            <div className={styles.mobileNavigationCta}>
              <a href={`/${ARCHIVED_PAGE_BASE_URL}/#${getCurrentHashMainPart()}`} key={title}>
                {title}
              </a>
              <button onClick={toggle(true)} className={styles.menuButton}>
                <img src="/menu.svg" alt="" />
              </button>
            </div>
          </div>
          {isMobileNavigationOpen && mobileNavigationContent}
        </>
      )}

      <LayoutGroup>{children}</LayoutGroup>
    </>
  );
}

function DesktopNavigation(props: { activeRoute: string }) {
  return (
    <nav className={styles.desktopNavigation}>
      <ul className={`${styles.desktopLinks} ${styles[props.activeRoute]}`}>
        {' '}
        <Links links={desktopLinks} activeRoute={props.activeRoute} />
      </ul>

      <div className={styles.logo}>
        <a href={`/${ARCHIVED_PAGE_BASE_URL}/#home`}>
          <img src="/logo.svg" alt="IncepTech Ltd." />
        </a>
      </div>
    </nav>
  );
}

function MobileNavigation({ onClose, currentHashMainPart }: { onClose: () => unknown; currentHashMainPart: string }) {
  return (
    <nav className={`${styles.mobileNavigationContent} ${styles.opened}`} onClick={onClose}>
      <div className={styles.mobileNavigationContentWrapper}>
        <a href={`/${ARCHIVED_PAGE_BASE_URL}/#home`}>
          <img src="/logo-horizontal.svg" alt="IncepTech Ltd." className={styles.mobileLogo} />
        </a>

        <ul>
          <Links links={defaultLinks} />
        </ul>
      </div>

      <div className={styles.mobileNavigationCloseCta}>
        <a href={`#${currentHashMainPart}`} onClick={onClose}>
          <img src="/close-icon.svg" alt="Close menu" />
        </a>
      </div>
    </nav>
  );
}

type LinkItem = {
  url: string;
  text: string;
};

const defaultLinks: LinkItem[] = [
  { url: `/${ARCHIVED_PAGE_BASE_URL}/#work`, text: 'Work' },
  { url: `/${ARCHIVED_PAGE_BASE_URL}/#team`, text: 'Team' },
  { url: `/${ARCHIVED_PAGE_BASE_URL}/#careers`, text: 'Careers' },
  { url: `/${ARCHIVED_PAGE_BASE_URL}/#company`, text: 'Company' },
  { url: `/${ARCHIVED_PAGE_BASE_URL}/#contact`, text: 'Contact' }
];

const desktopLinks = [...defaultLinks].reverse();

const Links = (props: { links: LinkItem[]; activeRoute?: string }) => {
  const currentHash = `/#${props.activeRoute}`;
  return (
    <>
      {props.links.map((link, index) => (
        <li
          key={index}
          className={currentHash === link.url.replace('_', '') || currentHash === link.url ? styles.activeRoute : ''}
        >
          <a href={link.url}>{link.text}</a>
        </li>
      ))}
    </>
  );
};
